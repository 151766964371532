export default [
    {
        name: "Abby",
        rating: 5,
        image: "/user_0.png",
        datetime: "December 7, 2020",
        comment: "Peter is very good, he installed our blinds so beautifully.  Really worth the money, blinds are very good quality and adds more beauty in our house.  It really stands out because of the uniqueness of it.  Highly recommend Soho blinds and Peter is really professional and friendly.",
    },
    {
        name: "Techsy",
        rating: 5,
        image: "/user_0.png",
        datetime: "October 5, 2020",
        comment: "Very good products, at the competitive price and love the service.",
    },
    {
        name: "HU-705227543",
        rating: 5,
        image: "/user_0.png",
        datetime: "September 18, 2020",
        comment: "I highly recommend Soho Blinds for their expertise in window furnishings. Peter has been very professional kind and friendly to us . He has done a amazingly beautiful job in giving my home a new look with the new Sheer blinds . Really appreciate Thank you Peter and your team .",
    },
    {
        name: "Liezl Anne Adriano",
        rating: 5,
        image: "/user_0.png",
        datetime: "September 15, 2020",
        comment: "Very good and quality material. Peter is very professional and friendly. Very good pricing too.",
    },
    {
        name: "HU-997726892",
        rating: 4,
        image: "/user_0.png",
        datetime: "September 11, 2020",
        comment: "A friend recommended them and we contacted them. They came to measure and were very reasonable. Due to COVID the blinds got delayed but they kept us updated. Very friendly and professional services.",
    },
    {
        name: "HU-23821318",
        rating: 5,
        image: "/user_0.png",
        datetime: "July 28, 2020",
        comment: "I had a very happy experience with Peter from SOHO Blinds.  I chose to go with Zebra blinds after he showed me a range of different colors and designs.  He took me to view another home that had just recently installed the zebra blinds and I was definitely convinced that my choice was the right one.  The installation was fast and clean my home looks so beautiful and it all ties in nicely.  I am a very satisfied customer and highly recommend him.  Thank you Peter and team.",
    },
    {
        name: "HU-671594530",
        rating: 5,
        image: "/user_0.png",
        datetime: "July 12, 2020",
        comment: "Great service, was punctual at all agreed times; was shown a good selection of blinds; got good advice; price was good.",
    },
    {
        name: "Li Quan",
        rating: 5,
        image: "/user_0.png",
        datetime: "June 2, 2020",
        comment: "Great product and service! Love the final work which perfectly matches the style of my brand new house! The team has done a beautifully neat and tidy work! Highly recommended!",
    },
    {
        name: "Nicolas C., Auckland",
        rating: 5,
        image: "/user_0.png",
        datetime: "April 2, 2020",
        comment: "Excellent service, great product, best price! We are so happy to have chosen Soho Blinds and the curtain products that the company offered. I have already recommended Soho Blinds to friends and family and will continue to do so.",
    },
    {
        name: "VISHAL AGARWAL",
        rating: 5,
        image: "/user_0.png",
        datetime: "March 25, 2020",
        comment: "Great service, good quality products, comparative price, service oriented, committed to do job in time , good communication, 5 YEARS WARRANTY ON PRODUCT AND SERVICE,",
    },
    {
        name: "Christine Keown",
        rating: 5,
        image: "/user_0.png",
        datetime: "March 22, 2020",
        comment: "The price was really good. Communication was excellent. Product was correct to order and works fine. The person we dealt with was very pleasant to work with.",
    },
]
