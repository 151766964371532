export default [
  {
    id: 'project1',
    name: "Hobsonvile, Auckland",
    link: "/projects/35-eyton-kay-road-pj-vj~6213604",
    image: "/photos/eyton-kay-road/1.jpg",
    address: "35 Eyton Kay Road.",
    images: [
        '/photos/eyton-kay-road/1.jpg',
        '/photos/eyton-kay-road/2.jpg',
        '/photos/eyton-kay-road/3.jpg',
        '/photos/eyton-kay-road/4.jpg',
        '/photos/eyton-kay-road/5.jpg',
        '/photos/eyton-kay-road/6.jpg',
        '/photos/eyton-kay-road/7.jpg',
        '/photos/eyton-kay-road/8.jpg',
        '/photos/eyton-kay-road/9.jpg',
        '/photos/eyton-kay-road/10.jpg',
        '/photos/eyton-kay-road/11.jpg',
        '/photos/eyton-kay-road/12.jpg',
        '/photos/eyton-kay-road/13.jpg',
        '/photos/eyton-kay-road/14.jpg',
        '/photos/eyton-kay-road/15.jpg',
        '/photos/eyton-kay-road/16.jpg',
        '/photos/eyton-kay-road/17.jpg',
        '/photos/eyton-kay-road/18.jpg',
        '/photos/eyton-kay-road/19.jpg',
        '/photos/eyton-kay-road/20.jpg',
    ],
  },
  {
    id: 'project2',
    name: "Blockhouse Bay, Auckland",
    link: "/projects/15-geneva-place-pj-vj~6356479",
    image: "/photos/geneva-place/1.jpg",
    address: "15 Geneva Place",
    images: [
        '/photos/geneva-place/1.jpg',
        '/photos/geneva-place/2.jpg',
        '/photos/geneva-place/3.jpg',
        '/photos/geneva-place/4.jpg',
        '/photos/geneva-place/5.jpg',
        '/photos/geneva-place/6.jpg',
        '/photos/geneva-place/7.jpg',
        '/photos/geneva-place/8.jpg',
        '/photos/geneva-place/9.jpg',
        '/photos/geneva-place/10.jpg',
        '/photos/geneva-place/11.jpg',
        '/photos/geneva-place/12.jpg',
        '/photos/geneva-place/13.jpg',
        '/photos/geneva-place/14.jpg',
        '/photos/geneva-place/15.jpg',
    ],
  },
  {
    id: 'project3',
    name: "Flat Bush, Auckland",
    link: "/projects/galway-street-pj-vj~6117224",
    image: "/photos/bushfield-drive/8.jpg",
    address: "Bushfield Drive",
    images: [
        '/photos/bushfield-drive/1.jpg',
        '/photos/bushfield-drive/2.jpg',
        '/photos/bushfield-drive/3.jpg',
        '/photos/bushfield-drive/4.jpg',
        '/photos/bushfield-drive/5.jpg',
        '/photos/bushfield-drive/6.jpg',
        '/photos/bushfield-drive/7.jpg',
        '/photos/bushfield-drive/8.jpg',
        '/photos/bushfield-drive/9.jpg',
        '/photos/bushfield-drive/10.jpg',
        '/photos/bushfield-drive/11.jpg',
        '/photos/bushfield-drive/12.jpg',
        '/photos/bushfield-drive/13.jpg',
        '/photos/bushfield-drive/14.jpg',
        '/photos/bushfield-drive/15.jpg',
        '/photos/bushfield-drive/16.jpg',
        '/photos/bushfield-drive/17.jpg',
        '/photos/bushfield-drive/18.jpg',
    ],
  },
  {
    id: 'project4',
    name: "Auckland CBD.",
    link: "/projects/lighter-quay-pj-vj~6117208",
    image: "/photos/lighter-quay/15.jpg",
    address: "Lighter Quay.",
    images: [
        '/photos/lighter-quay/1.jpg',
        '/photos/lighter-quay/2.jpg',
        '/photos/lighter-quay/3.jpg',
        '/photos/lighter-quay/4.jpg',
        '/photos/lighter-quay/5.jpg',
        '/photos/lighter-quay/6.jpg',
        '/photos/lighter-quay/7.jpg',
        '/photos/lighter-quay/8.jpg',
        '/photos/lighter-quay/9.jpg',
        '/photos/lighter-quay/10.jpg',
        '/photos/lighter-quay/11.jpg',
        '/photos/lighter-quay/12.jpg',
        '/photos/lighter-quay/13.jpg',
        '/photos/lighter-quay/14.jpg',
        '/photos/lighter-quay/15.jpg',
        '/photos/lighter-quay/16.jpg',
        '/photos/lighter-quay/17.jpg',
        '/photos/lighter-quay/18.jpg',
        '/photos/lighter-quay/19.jpg',
        '/photos/lighter-quay/20.jpg',
        '/photos/lighter-quay/21.jpg',
        '/photos/lighter-quay/22.jpg',
        '/photos/lighter-quay/23.jpg',
        '/photos/lighter-quay/24.jpg',
        '/photos/lighter-quay/25.jpg',
        '/photos/lighter-quay/26.jpg',
        '/photos/lighter-quay/27.jpg',
        '/photos/lighter-quay/28.jpg',
        '/photos/lighter-quay/29.jpg',
    ],
  },
  {
    id: 'project5',
    name: "Rosehill, Papakura.",
    link: "/projects/kristy-harrison-horomia-road-pj-vj~6117212",
    image: "/photos/rosehill/1.jpg",
    address: "Kristy Harrison Horimia Road.",
    images: [
        '/photos/rosehill/1.jpg',
        '/photos/rosehill/2.jpg',
        '/photos/rosehill/3.jpg',
        '/photos/rosehill/4.jpg',
        '/photos/rosehill/5.jpg',
        '/photos/rosehill/6.jpg',
        '/photos/rosehill/7.jpg',
        '/photos/rosehill/8.jpg',
        '/photos/rosehill/9.jpg',
        '/photos/rosehill/10.jpg',
        '/photos/rosehill/11.jpg',
        '/photos/rosehill/12.jpg',
        '/photos/rosehill/13.jpg',
        '/photos/rosehill/14.jpg',
    ],
  },
  {
    id: 'project6',
    name: "Flat Bush, Auckland",
    link: "/projects/hannah-project-pj-vj~6243697",
    image: "/photos/thomas-road/1.jpg",
    address: "120 Thomas Road",
    images: [
        '/photos/thomas-road/1.jpg',
        '/photos/thomas-road/2.jpg',
        '/photos/thomas-road/3.jpg',
        '/photos/thomas-road/4.jpg',
        '/photos/thomas-road/5.jpg',
        '/photos/thomas-road/6.jpg',
        '/photos/thomas-road/7.jpg',
        '/photos/thomas-road/8.jpg',
        '/photos/thomas-road/9.jpg',
        '/photos/thomas-road/10.jpg',
        '/photos/thomas-road/11.jpg',
        '/photos/thomas-road/12.jpg',
        '/photos/thomas-road/13.jpg',
        '/photos/thomas-road/14.jpg',
        '/photos/thomas-road/15.jpg',
        '/photos/thomas-road/16.jpg',
        '/photos/thomas-road/17.jpg',
        '/photos/thomas-road/18.jpg',
        '/photos/thomas-road/19.jpg',
        '/photos/thomas-road/20.jpg',
        '/photos/thomas-road/21.jpg',
        '/photos/thomas-road/22.jpg',
        '/photos/thomas-road/23.jpg',
        '/photos/thomas-road/24.jpg',
        '/photos/thomas-road/25.jpg',
        '/photos/thomas-road/26.jpg',
        '/photos/thomas-road/27.jpg',
        '/photos/thomas-road/28.jpg',
        '/photos/thomas-road/29.jpg',
        '/photos/thomas-road/30.jpg',
    ],
  },
]
